import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    Badge,
    Box,
    ButtonBase,
    CardActions,
    CardContent,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery
} from '@material-ui/core';
// import ListItemButton from '@material-ui/core/ListItemButton';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import NotificationList from './NotificationList';

// assets
import { IconBell, IconLogout } from '@tabler/icons';

import { createSorterUtilitiesServiceEndpoint2, SORTER_UTILITIES_SERVICE_ENDPOINTS } from '../../../../api';

const collect = require('collect.js');

// style constant
const useStyles = makeStyles((theme) => ({
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 205px)',
        overflowX: 'hidden'
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.largeAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
        '&[aria-controls="menu-list-grow"],&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
        }
    },
    cardContent: {
        padding: '0px !important'
    },
    notificationChip: {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.warning.dark
    },
    divider: {
        marginTop: 0,
        marginBottom: 0
    },
    cardAction: {
        padding: '10px',
        justifyContent: 'center'
    },
    paddingBottom: {
        paddingBottom: '16px'
    },
    box: {
        marginLeft: '16px',
        marginRight: '24px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '16px'
        }
    },
    bodyPPacing: {
        padding: '16px 16px 0'
    },
    textBoxSpacing: {
        padding: '0px 16px'
    },
    count: {
        position: 'absolute',
        lineHeight: 1,
        color: '#FF0000',
        top: '0.2em',
        right: '0.4em',
        fontSize: '0.5em',
        fontWeight: 'bold'
    },
    customBadge: {
        backgroundColor: '#FF0000',
        color: 'white'
    }
}));

// ===========================|| NOTIFICATION ||=========================== //

const NotificationSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));
    const customization = useSelector((state) => state.customization);

    const [open, setOpen] = React.useState(false);
    const [notificationCount, setNotificationCount] = React.useState(0);
    const [errorMessageList, setErrorMessageList] = React.useState([]);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const fetchErrorsWithSearchParams = (paramStr) => {
        createSorterUtilitiesServiceEndpoint2(SORTER_UTILITIES_SERVICE_ENDPOINTS.MESSAGELOGERRORS)
            .fetchByMultipleParams(paramStr)
            .then((res) => {
                if (res.data.errorMessages !== null) {
                    const list = res.data.errorMessages.messageLogs.map((item) => ({
                        interface: item.interfaceType,
                        messageType: item.messageType,
                        equipmentNumber: item.equipmentNumber,
                        error: item.error,
                        messageId: item.messageId,
                        directionType: item.directionType,
                        time: item.createDate
                    }));
                    const newList = collect({ list }).except(errorMessageList).all();
                    setErrorMessageList(newList.list);
                    setNotificationCount(newList.list.length);
                }
            })
            .catch((err) => {
                console.log(err, 'caught EXCEPTION in fetchWithSearchParams');
            });
    };

    function subtractHours(numOfHours, date = new Date()) {
        date.setHours(date.getHours() - numOfHours);
        return date;
    }

    const fetchErrorMessagesForLastHour = () => {
        const dtEnd = null;
        const dtStart = null;
        const dtStartStr = dtStart === null ? subtractHours(1).toString() : dtStart.toString();
        const dtEndStr = dtEnd === null ? new Date().toString() : dtEnd.toString();
        const realDTStart = dtStartStr.trim().split('G')[0];
        const realDTEnd = dtEndStr.trim().split('G')[0];
        const paramStr = `?starttime=${encodeURIComponent(realDTStart)}&endtime=${encodeURIComponent(realDTEnd)}`;

        fetchErrorsWithSearchParams(paramStr);
    };

    const prevOpen = React.useRef(open);
    const MINUTE_MS = 60000;

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
        if (!open) {
            setErrorMessageList([]);
            setNotificationCount(0);
        }
    }, [open]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            fetchErrorMessagesForLastHour();
        }, MINUTE_MS);
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // React.useEffect(
    //     () => () => {
    //         setOpen(false);
    //     },
    //     []
    // );

    const navigate = useNavigate();

    const handleGoToMessages = () => {
        navigate('/dashboard/MessagesLog');
        setOpen(false);
    };

    function getTime(dt) {
        // eslint-disable-next-line no-unused-vars
        const [d, t, ampm] = dt.split(' ');
        return `${t} ${ampm}`;
    }

    return (
        <>
            <Box component="span" className={classes.box}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        className={classes.headerAvatar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <Badge badgeContent={notificationCount} classes={{ badge: classes.customBadge }}>
                            <IconBell stroke={1.5} size="1.3rem" />
                        </Badge>
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position="top-right" in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <div className={classes.bodyPPacing}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>
                                                            <Typography variant="subtitle1">Last Hour Activity:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider className={classes.divider} />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar className={classes.ScrollHeight}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12}>
                                                            <div className={classes.textBoxSpacing}>
                                                                <Typography>{errorMessageList.length} Recently Failed Messages</Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {errorMessageList.length > 0 && (
                                                                <TableContainer component={Paper}>
                                                                    <Table sx={{ maxWidth: 450 }} size="small">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Time</TableCell>
                                                                                <TableCell>MessageType</TableCell>
                                                                                <TableCell>EquipmentNumber</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {errorMessageList.map((row, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell
                                                                                        align="left"
                                                                                        sx={{ maxWidth: 450, fontSize: '8pt' }}
                                                                                    >
                                                                                        {getTime(row.time)}
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="left"
                                                                                        sx={{ maxWidth: 400, fontSize: '8pt' }}
                                                                                    >
                                                                                        {row.messageType}
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="left"
                                                                                        sx={{ maxWidth: 400, fontSize: '8pt' }}
                                                                                    >
                                                                                        {row.error.equipmentNumber}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider />
                                    <List component="nav" className={classes.navContainer}>
                                        <ListItemButton
                                            className={classes.listItem}
                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                            // selected={selectedIndex === 4}
                                            onClick={handleGoToMessages}
                                        >
                                            <ListItemIcon>
                                                <IconLogout stroke={1.5} size="1.3rem" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body2" color="secondary">
                                                        Go to Messages Log
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </List>

                                    <CardActions className={classes.cardAction}>
                                        {/* <Button component="button" variant="body2" onClick={handleGoToMessages()}>
                                            Go to Messages Log
                                        </Button> */}
                                        {/* <Button
                                            component={Link}
                                            to="/dashboard/MessagesLog"
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                        >
                                            Go to Messages Log
                                        </Button> */}
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
