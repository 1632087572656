import axios from 'axios';

const CCVT_BASE_URL = 'https://localhost:44343/api/';
const DCMS_BASE_URL = 'https://10.254.50.225:5001/api/';
const AUTHENTICATION_BASE_URL = 'https://wnd-auth.azurewebsites.net/api/';
export const SORTER_UTILITIES_SERVICE_BASE_URL = 'https://wnd-sorterutilitiesapi.azurewebsites.net/Internal/V1.0/';

const HEADER = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        timeout: 30000,
        'x-timezone': new Date()
            .toLocaleTimeString(navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language, {
                timeZoneName: 'short'
            })
            .toLowerCase()
            .split(' ')[2],
        Authorization:
            'Bearer ' +
            'meyTd6vgdPJq9yG7mMjENU1w7eJQm?km0AvM8iI9V3vwhuxyU6jTe8nq@aRs38?96!00ibU@5gFLDNF2qYQc6aXscStl43aQ77H1x5dNjcTfI!4fso?t0fqn05AoUSEYcu2tqL3svTfSqxTY1AdvGY9@0ooN23',
        Basic:
            'Bearer ' +
            'meyTd6vgdPJq9yG7mMjENU1w7eJQm?km0AvM8iI9V3vwhuxyU6jTe8nq@aRs38?96!00ibU@5gFLDNF2qYQc6aXscStl43aQ77H1x5dNjcTfI!4fso?t0fqn05AoUSEYcu2tqL3svTfSqxTY1AdvGY9@0ooN23'
    }
};

export const SORTER_UTILITIES_SERVICE_ENDPOINTS = {
    CUSTOMERALL: 'Customer/GetCustomersAll',
    CUSTOMERBYID: 'Customer/GetCustomerByID',
    CREATECUSTOMER: 'Customer/CreateCustomer',
    UPDATECUSTOMER: 'Customer/UpdateCustomer',
    DELETECUSTOMER: 'Customer/DeleteCustomer?CustomerId=',
    DASHBOARDCONTAINERSTATSALL: 'Dashboard/GetContainerStatsAll',
    DASHBOARDCONTAINERSTATSACTIVE: 'Dashboard/GetContainerStatsActive',
    DASHBOARDCONTAINERSTATSBYID: 'Dashboard/GetContainerStatsByID?ContainerId=',
    DASHBOARDTRAILERSTATSALL: 'Dashboard/GetTrailerStatsAll',
    DASHBOARDTRAILERSTATSBYID: 'Dashboard/GetTrailerStatsByID?TrailerId=',
    DASHBOARDCONTAINERCARTONDETAILS: 'Dashboard/GetContainerCartonDetails?ContainerId=',
    DASHBOARDTRAILERCARTONDETAILS: 'Dashboard/GetTrailerCartonDetails?TrailerId=',
    MESSAGEMESSAGECOUNTS: 'Message/GetMessageCounts',
    MESSAGEMESSAGECOUNTSBYHOUR: 'Message/GetMessageCountsByHour?LastNumOfHours=',
    MESSAGECOUNTSBYINTERFACETYPE: 'Message/GetMessageCountsByInterfaceType',
    MESSAGELOGS: 'Message/GetMessageLogs',
    MESSAGELOGERRORS: 'Message/GetMessageLogErrors',
    MESSAGERETRIGGERMESSAGE: 'Message/RetriggerMessage',
    MESSAGEINTERFACETYPES: 'Message/GetInterfaceTypes',
    FACILITYPRINTERALL: 'FacilityPrinter/GetFacilityPrinterAll',
    FACILITYCREATEPRINTER: 'FacilityPrinter/CreateFacilityPrinter',
    FACILITYDELETEPRINTER: 'FacilityPrinter/DeleteFacilityPrinter',
    FACILITYWCSWCS: 'FacilityWCS/GetFacilityWCS',
    FACILITYWCSCREATEWCS: 'FacilityWCS/CreateFacilityWCS',
    FACILITYWCSDELETEWCS: 'FacilityWCS/DeleteFacilityWCS',
    FACILITYWMSWMSALL: 'FacilityWMS/GetFacilityWMSAll',
    FACILITYWMSCREATEWMS: 'FacilityWMS/CreateFacilityWMS',
    FACILITYWMSDELETEWMS: 'FacilityWMS/DeleteFacilityWMS',
    FACILITYWCSLANES: 'FacilityWCSLane/GetFacilityWCSLanes',
    FACILITYWCSCREATEWCSLANE: 'FacilityWCSLane/CreateFacilityWCSLane',
    FACILITYWCSUPDATEWCSLANE: 'FacilityWCSLane/UpdateFacilityWCSLane',
    FACILITYWCSDELETEWCSLANE: 'FacilityWCSLane/DeleteFacilityWCSLane',
    CONTAINERCARTONS: 'SorterTool/GetContainerCartons?ContainerId=',
    EXCEPTIONCARTONS: 'SorterTool/GetExceptionCartons',
    CONTAINERCARTONUCC: 'SorterTool/GetContainerCartonUCC?UCC=',
    RECEIVECARTON: 'SorterTool/ReceiveCarton',
    RECEIVECARTONOVERAGE: 'SorterTool/ReceiveCartonOverage',
    RECEIVEROUTECARTON: 'SorterTool/ReceiveRouteCarton',
    RECEIVEROUTECARTONOVERAGE: 'SorterTool/ReceiveRouteCartonOverage',
    CREATEPRINTLABEL: 'SorterTool/CreatePrintLabel',
    GETPRINTLABEL: 'SorterTool/GetPrintLabel?CartonID=',
    ASSIGNEQUIPMENTTODOOR: 'SorterTool/AssignEquipmentToDoor',
    ROUTINGINSTRUCTION: 'SorterTool/RoutingInstruction',
    EQUIPMENTS: 'SorterTool/GetEquipments'
};

export const AUTHENTICATION_ENDPOINTS = {
    SEARCHUSER: 'search_user',
    DELETEUSER: 'delete_user',
    UPDATEUSER: 'update_user',
    ADDUSER: 'add_user'
};

export const createSorterUtilitiesServiceEndpoint = (endpoint) => {
    // eslint-disable-next-line no-useless-concat
    const url = `${SORTER_UTILITIES_SERVICE_BASE_URL + endpoint}` + '/';
    console.log(HEADER, '<==== HEADER');
    const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
    console.log(userLocale, '<==== userLocale'); // 👉️ "en-US"
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            timeout: 30000,
            Authorization:
                'Bearer ' +
                'meyTd6vgdPJq9yG7mMjENU1w7eJQm?km0AvM8iI9V3vwhuxyU6jTe8nq@aRs38?96!00ibU@5gFLDNF2qYQc6aXscStl43aQ77H1x5dNjcTfI!4fso?t0fqn05AoUSEYcu2tqL3svTfSqxTY1AdvGY9@0ooN23'
        }
    };

    return {
        fetchAll: () => axios.get(url, config),
        fetchById: (id) => axios.get(url + id, config),
        create: (newRecord) => axios.post(url, newRecord, HEADER),
        update: (updatedRecord) => axios.put(url, updatedRecord, HEADER),
        deleteById: (id) => axios.delete(url + id, HEADER),
        deleteByName: (name) => axios.delete(`${url}?Name=${name}`, HEADER),
        deleteByIdentityID: (id) => axios.delete(`${url}?ID=${id}`, HEADER)
    };
};

export const createSorterUtilitiesServiceEndpoint2 = (endpoint) => {
    const url = `${SORTER_UTILITIES_SERVICE_BASE_URL + endpoint}`;
    const config = {
        headers: {
            timeout: 40000,
            Authorization:
                'Bearer ' +
                'meyTd6vgdPJq9yG7mMjENU1w7eJQm?km0AvM8iI9V3vwhuxyU6jTe8nq@aRs38?96!00ibU@5gFLDNF2qYQc6aXscStl43aQ77H1x5dNjcTfI!4fso?t0fqn05AoUSEYcu2tqL3svTfSqxTY1AdvGY9@0ooN23'
        }
    };
    return {
        fetchByName: (name) => axios.get(url + name, config),
        fetchByMultipleParams: (params) => axios.get(url + params, config),
        fetchByHour: (hours) => axios.get(url + hours, config),
        deleteById: (id) => axios.delete(url + id, config)
    };
};

export const createSorterUtilitiesServiceEndpointAsync = (endpoint) => {
    const url = `${SORTER_UTILITIES_SERVICE_BASE_URL + endpoint}`;
    return {
        fetchByName: (name) => axios.get(url + name, HEADER)
    };
};

export const createSorterUtilitiesServiceEndpointFetchByName = async (endpoint, name) => {
    const url = `${SORTER_UTILITIES_SERVICE_BASE_URL + endpoint}`;
    let response;
    try {
        response = await axios.get(url + name, HEADER);
    } catch (e) {
        // catch error
        throw new Error(e.message);
    }
    // if success return value
    return response?.data ? response?.data : null; // or set initial value
};

export const createAuthenticationEndpoint = (endpoint) => {
    const url = `${AUTHENTICATION_BASE_URL + endpoint}`;
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*', // true
            'Content-Type': 'application/json',
            timeout: 40000
        }
    };
    return {
        search: (params) => axios.get(url + params, config),
        create: (newRecord) => axios.post(url, newRecord, config),
        update: (updateRecord) => axios.post(url, updateRecord, config),
        deleteById: (id) => axios.delete(`${url}?Id=${id}`, config)
    };
};

// const createParamsStrForNewUser = (user) => {
//     let paramStr = `?FirstName=${encodeURIComponent(user.firstName)}`;
//     paramStr += `&LastName=${encodeURIComponent(user.lastName)}`;
//     paramStr += `&Email=${encodeURIComponent(user.email)}`;
//     paramStr += `&UserName=${encodeURIComponent(user.userName)}`;
//     return paramStr;
// };

// const getCurrentUser = (user) => {
//     const paramStr = createParamsStrForNewUser(user);

//     createAuthenticationEndpoint(AUTHENTICATION_ENDPOINTS.SEARCHUSER)
//         .search(paramStr)
//         // eslint-disable-next-line consistent-return
//         .then((res) => {
//             if (res.data.users.length > 0) {
//                 console.log(res.data.users(), '<-- res.data.users');
//                 const list = res.data.users.map((item) => ({
//                     firstName: item.firstName,
//                     lastName: item.lastname,
//                     userName: item.userName,
//                     email: item.email,
//                     role: item.role,
//                     company: item.companyName,
//                     id: item.id
//                 }));
//                 if (list.length > 0) {
//                     return list[0];
//                 }
//             }
//         })
//         .catch((err) => {});
//     return false;
// };

export const CCVT_ENDPOINTS = {
    CONTAINER: 'container',
    CONTAINERBYCUSTID: 'container/customer',
    CUSTOMER: 'customer'
};

export const DCMS_ENDPOINTS = {
    REQUESTS: 'Requests'
};

export const createCCVTAPIEndpoint = (endpoint) => {
    const url = `${CCVT_BASE_URL + endpoint}/`;

    return {
        fetchAll: () => axios.get(url),
        fetchById: (id) => axios.get(url + id),
        create: (newRecord) => axios.post(url, newRecord),
        update: (id, updatedRecord) => axios.put(url, updatedRecord),
        delete: (id) => axios.delete(url + id)
    };
};

export const createDCMSAPIEndpoint = (endpoint) => {
    const url = `${DCMS_BASE_URL + endpoint}/`;

    return {
        requests: () => axios.post(url)
    };
};

export const getUserFullName = () => {
    const userFullName = window.localStorage.getItem('userFullName');
    if (userFullName) {
        return userFullName;
    }
    return 'Dashboard Guest';
};

export const getUserRole = () => {
    const role = window.localStorage.getItem('userRole');
    if (role) {
        if (role === '' || role === null) {
            return null;
        }
        const roleInt = role ? parseInt(role, 10) : 0;
        return roleInt;
    }
    return null;
};
