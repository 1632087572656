import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@material-ui/core';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconDeviceAnalytics };

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'ContainerTrailerView',
            title: <FormattedMessage id="Containers / Trailers" />,
            type: 'collapse',
            url: '/dashboard/ContainerTrailerView',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false,
            children: [
                {
                    id: 'tests7',
                    title: <FormattedMessage id="Container View" />,
                    type: 'item',
                    url: '/dashboard/ContainerView',
                    breadcrumbs: false
                },
                {
                    id: 'tests8',
                    title: <FormattedMessage id="Trailer View" />,
                    type: 'item',
                    url: '/dashboard/TrailerView',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'equipmentLaneAssignment',
            title: <FormattedMessage id="Equipment Lane Assignments" />,
            type: 'collapse',
            url: '/dashboard/EquipmentLaneAssignment',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false,
            children: [
                {
                    id: 'tests6',
                    title: <FormattedMessage id="Data and Lanes" />,
                    type: 'item',
                    url: '/dashboard/EquipmentLaneAssignment',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'messages',
            title: <FormattedMessage id="Messages" />,
            type: 'collapse',
            url: '/dashboard/Messages',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false,
            children: [
                {
                    id: 'tests9',
                    title: <FormattedMessage id="Message Chart" />,
                    type: 'item',
                    url: '/dashboard/Messages',
                    breadcrumbs: false
                },
                {
                    id: 'tests10',
                    title: <FormattedMessage id="Message Log" />,
                    type: 'item',
                    url: '/dashboard/MessagesLog',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'sorterTools',
            title: (
                <Tooltip title="Feature not needed for SAV6">
                    <FormattedMessage id="Sorter Tools" />
                </Tooltip>
            ),
            type: 'collapseDisable',
            disable: true,
            url: '/dashboard/SorterTools',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false,
            children: [
                {
                    id: 'tests11',
                    title: <FormattedMessage id="Reject Carton Resolution" />,
                    type: 'item',
                    url: '/dashboard/SorterTools',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'configuration',
            title: <FormattedMessage id="Configuration" />,
            type: 'collapse',
            url: '/dashboard/Configuration',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false,
            children: [
                {
                    id: 'tests',
                    title: <FormattedMessage id="Facility Information" />,
                    type: 'item',
                    url: '/dashboard/Configuration',
                    breadcrumbs: false
                },
                {
                    id: 'tests2',
                    title: <FormattedMessage id="DoorToLane Mapping" />,
                    type: 'item',
                    url: '/dashboard/DoorToLaneMapping',
                    breadcrumbs: false
                },
                {
                    id: 'tests3',
                    title: <FormattedMessage id="Distribution Centers" />,
                    type: 'item',
                    url: '/dashboard/DistributionCenters',
                    breadcrumbs: false
                },
                {
                    id: 'tests4',
                    title: <FormattedMessage id="Printers" />,
                    type: 'item',
                    url: '/dashboard/Printers',
                    breadcrumbs: false
                },
                /* Below code commented out (story MOSI-2482 SS Dashboard - Remove Email Service Menu Option) */
                // {
                //     id: 'tests5',
                //     title: <FormattedMessage id="Email Service" />,
                //     type: 'item',
                //     url: '/dashboard/EmailAddresses',
                //     breadcrumbs: false
                // },
                {
                    id: 'tests6',
                    title: <FormattedMessage id="User Management" />,
                    type: 'item',
                    url: '/dashboard/UserManagement',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default dashboard;
