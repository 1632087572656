/* eslint-disable react/prop-types */
import React from 'react';
import { Alert, Slide, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        top: theme.spacing(9),
        '& .MuiAlert-root': {
            backgroundColor: '#f3b33d',
            color: '#000'
        },
        '& .MuiAlert-icon': {
            color: '#000'
        }
    }
}));

export default function Notification(props) {
    // eslint-disable-next-line react/prop-types
    const { notify, setNotify } = props;
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setNotify({
            ...notify,
            isOpen: false
        });
    };

    return (
        <Snackbar
            autoHideDuration={5000}
            className={classes.root}
            // eslint-disable-next-line react/prop-types
            open={notify.isOpen}
            TransitionComponent={Slide}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={handleClose}
        >
            <Alert onClose={handleClose}>{notify.message} </Alert>
        </Snackbar>
    );
}
