import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import axios from 'axios';
import Loader from 'ui-component/Loader';
import { encode as base64Encode, decode as base64Decode } from 'base-64';

import Notification from '../views/forms/layouts/Notification';
import { SORTER_UTILITIES_SERVICE_BASE_URL, createAuthenticationEndpoint, AUTHENTICATION_ENDPOINTS } from '../api';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    // // const decoded = jwtDecode(serviceToken);
    // // return decoded.exp > Date.now() / 1000;
    // const user = base64Decode(serviceToken);
    return true;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const setUserFullName = (user) => {
    if (user) {
        window.localStorage.setItem('userFullName', `${user.firstName} ${user.lastName}`);
    } else {
        window.localStorage.removeItem('userFullName');
    }
};

const createParamsString = (user) => {
    let paramStr = '';
    if (user) {
        paramStr = `?FirstName=${encodeURIComponent(user.firstName)}`;
        paramStr += `&LastName=${encodeURIComponent(user.lastName)}`;
        paramStr += `&Email=${encodeURIComponent(user.email)}`;
        paramStr += `&UserName=${encodeURIComponent(user.username)}`;
    }
    return paramStr;
};

const fetchUserRole = (user) => {
    const paramsString = createParamsString(user);

    createAuthenticationEndpoint(AUTHENTICATION_ENDPOINTS.SEARCHUSER)
        .search(paramsString)
        // eslint-disable-next-line consistent-return
        .then((res) => {
            if (res.data.users.length > 0) {
                const list = res.data.users.map((item) => ({
                    firstName: item.firstName,
                    lastName: item.lastname,
                    userName: item.userName,
                    email: item.email,
                    password: '******',
                    role: item.role,
                    company: item.companyName,
                    id: item.id
                }));
                // if (list.length > 0) return list[0].role;
                window.localStorage.setItem('userRole', list[0].role);
            } else {
                // setNotify({ isOpen: true, message: `No user records found matching criteria.`, duration: 5000 });
            }
        })
        .catch((err) => {
            console.log(err, 'caught EXCEPTION in searchUser endpoint');
            // setNotify({ isOpen: true, message: `Trouble searching for users.`, duration: 7000 });
        });
    return 1;
};

const setUserRole = async (user) => {
    if (user) {
        const role = fetchUserRole(user);
        window.localStorage.setItem('userRole', role);
    } else {
        window.localStorage.removeItem('userRole');
    }
};

// ===========================|| JWT CONTEXT & PROVIDER ||=========================== //

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {}
});

export const JWTProvider = ({ children }) => {
    const SORTER_UTILITIES_LOGIN_URL = `${SORTER_UTILITIES_SERVICE_BASE_URL}User/Login`;
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [notify, setNotify] = React.useState({ isOpen: false, message: '', duration: 5000 });
    // eslint-disable-next-line no-unused-vars
    const [currentUser, setCurrentUser] = React.useState({});

    const login = async (username, password) => {
        const serviceToken = base64Encode(`${username.toLowerCase()}:${password}`); /* MOSI-2544 Update Username Case Sensitivity */
        const basicAuth = `basic ${serviceToken}`;

        await axios
            .get(SORTER_UTILITIES_LOGIN_URL, {
                headers: { Authorization: basicAuth }
            })
            .then((response) => {
                console.log('Authenticated!');
                console.log(response.data);
                // setPost(response.data);
                const { user } = response.data;
                setSession(serviceToken);
                setUserFullName(user);
                setUserRole(user);
                dispatch({
                    type: LOGIN,
                    payload: {
                        user
                    }
                });
                setCurrentUser(user);
                return user;
            })
            .catch((error) => {
                console.log(error, '<--- Exception caught here');
                setNotify({ isOpen: true, message: 'Invalid credentials. Please try again.', duration: 6000 });
            });
        return null;
    };

    const logout = () => {
        setSession(null);
        setUserFullName(null);
        setUserRole(null);
        // dispatch({ type: LOGOUT });
        dispatch({
            type: LOGOUT,
            payload: {
                isLoggedIn: false,
                user: null
            }
        });
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    // const response = await axios.get('/api/account/me');
                    const userObj = base64Decode(serviceToken);
                    if (!userObj) {
                        setUserFullName(null);
                        setUserRole(null);
                        setSession(null);
                        dispatch({
                            type: ACCOUNT_INITIALIZE,
                            payload: {
                                isLoggedIn: false,
                                user: null
                            }
                        });
                    }
                    const user = { username: userObj.username, password: userObj.password };
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    setUserFullName(null);
                    setUserRole(null);
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err, '<-- caught exception');
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        user: null
                    }
                });
            }
        };

        init();
    }, []);

    if (!state.isInitialized) {
        return <Loader />;
    }

    <Notification {...{ notify, setNotify }} />;

    return (
        <>
            <JWTContext.Provider value={{ ...state, login, logout }}>
                {children}
                <Notification {...{ notify, setNotify }} />
            </JWTContext.Provider>
            ;
        </>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
